import React from "react";
import Box from "@mui/material/Box";
import { Typography, IconButton, useMediaQuery } from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";
import shield from "../../assets/shield.png";

interface ClanSliderProps {
  clans: {
    id: string;
    coverImage: string;
    title: string;
  }[];
  activeSlide: number;
  setActiveSlide: (index: number) => void;
  next: () => void;
  prev: () => void;
  slideWidth: number;
  slideHeight: number;
}

const ClanSlider: React.FC<ClanSliderProps> = ({
  clans,
  activeSlide,
  setActiveSlide,
  next,
  prev,
  slideWidth,
  slideHeight,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const getStyles = (index: number) => {
    const screenWidth = window.innerWidth;
    const baseTranslateX = isMobile ? screenWidth * 0.15 : screenWidth * 0.17;
    const baseTranslateZ = isMobile ? screenWidth * 0.5 : screenWidth * 0.2;

    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(-35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 8,
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 8,
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 7,
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 7,
      };
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "80%" }}>
      <div
        className="slideC"
        {...handlers}
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          perspective: "1000px",
          height: slideHeight,
          width: slideWidth,
        }}
      >
        {clans.map((item, i) => (
          <div
            key={item.id}
            className="slide"
            style={{
              position: "absolute",
              width: slideWidth,
              height: slideHeight,
              transition: "transform 0.5s ease, opacity 0.5s ease",
              ...getStyles(i),
            }}
            onClick={() => setActiveSlide(i)}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                clipPath: "path('M <...shield_path_data...>')",
                backgroundImage: `url(${item.coverImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img
                src={shield}
                alt="Shield"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
              />
            </Box>
          </div>
        ))}
      </div>

      <Typography color="black" align="center">
        {clans[activeSlide]?.title}
      </Typography>

      <div className="btns">
        <IconButton
          className="slider-btn prev-btn"
          onClick={prev}
          sx={{ color: "red" }}
          aria-label="Previous slide"
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          className="slider-btn next-btn"
          onClick={next}
          sx={{ color: "red" }}
          aria-label="Next slide"
        >
          <ArrowForward />
        </IconButton>
      </div>
    </Box>
  );
};

export default ClanSlider;
