import React, { useEffect, useState, useRef } from "react";
import {
  Grid2 as Grid,
  LinearProgress,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import counterInstance from "../../api/counterConfig";
import { UserWithCoins, UserCounters } from "../../types";
import {
  CHAMPS_API,
  CLANS_LIST_API,
  USER_BY_IDS_API,
  USER_COUNTER_API,
  CLANS_DETAILS_API,
} from "../../api/endpoint";
import TimelineButtons from "./timeline-buttons";
import ChampIcons from "./champ-icons";
import TopChamps from "./top-champs";
import Leaderboard from "./leaderboard";
import IconsContainer from "../../svg/icons-container";
import user_profile_mobile from "../../assets/user_profile_mobile.svg";
import user_profile_desktop from "../../assets/user_profile_desktop.svg";

const parseCounter = (counter: { N: string } | undefined): number =>
  parseInt(counter?.N || "0", 10);

const Champs = () => {
  const [timeline, setTimeline] = useState("all_time");
  const [champType, setChampType] = useState("coins");
  const [userDetails, setUserDetails] = useState<UserWithCoins[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pageToken, setPageToken] = useState<string | null>(null);
  const [stopFetching, setStopFetching] = useState(false);
  const isFetching = useRef(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  // Fetch champions or clans based on champType and timeline passed as parameters
  const fetchData = async (
    type: string,
    period: string,
    currentPageToken: string | null = null,
  ) => {
    if (isFetching.current || stopFetching) return;

    isFetching.current = true;
    setLoading(true);
    setError(null);

    try {
      const response =
        type === "clans"
          ? await fetchClans(currentPageToken)
          : await fetchChamps(type, period, currentPageToken);

      const newPageToken = response?.page || null;
      if (!newPageToken) setStopFetching(true);

      setPageToken(newPageToken);
    } catch (error) {
      setError("An error occurred while fetching data");
    } finally {
      setLoading(false);
      isFetching.current = false;
    }
  };

  // Fetch champion data
  const fetchChamps = async (
    type: string,
    period: string,
    currentPageToken: string | null = null,
  ) => {
    try {
      const response = await axiosInstance.get(`${CHAMPS_API}/${type}`, {
        params: {
          timeline: period,
          count: 10,
          page: currentPageToken,
        },
      });

      const champData = response.data?.data || [];
      const userIds = champData.map(
        (champ: { user_id: string }) => champ.user_id,
      );

      if (userIds.length) {
        const userDetailsResponse = await axiosInstance.get(
          `${USER_BY_IDS_API}${userIds.join(",")}`,
        );
        const userDetails = userDetailsResponse.data.data || [];
        const mergedData = champData.map((champ: any) => ({
          ...champ,
          ...userDetails.find((user: any) => user.uid === champ.user_id),
          defaultAvatarPath: getRandomAvatarPath(),
        }));

        setUserDetails((prevDetails) => [...prevDetails, ...mergedData]);
      }

      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch champions");
    }
  };

  // Fetch clan data and extract member details
  const fetchClans = async (currentPageToken: string | null = null) => {
    try {
      const response = await axiosInstance.get(CLANS_LIST_API, {
        params: { sortByCoins: true, page: currentPageToken, count: 5 },
      });

      const clanData = response.data?.data || [];
      const allUserDetails: any[] = [];

      await Promise.all(
        clanData.map(async (clan: any) => {
          const clanDetailsResponse = await axiosInstance.get(
            CLANS_DETAILS_API,
            {
              params: { clan_id: clan.clan_id },
            },
          );
          const clanDetails = clanDetailsResponse.data?.data || {};
          const memberIds = clanDetails.clan_members.map(
            (member: any) => member.member_id,
          );

          // Fetch user details for each clan member and resolve counters
          const userDetailsResponse = await axiosInstance.get(
            `${USER_BY_IDS_API}${memberIds.join(",")}`,
          );
          const membersWithDetails = await Promise.all(
            userDetailsResponse.data.data.map(async (member: any) => {
              const countersResponse = await counterInstance.get(
                `${USER_COUNTER_API}/${member?.uid}`,
              );
              const counters = countersResponse.data?.Item;

              if (counters) {
                const newCounters: Partial<UserCounters> = {};
                for (const key in counters) {
                  if (counters[key].N) {
                    newCounters[key as keyof UserCounters] = parseCounter(
                      counters[key],
                    );
                  }
                }
                return {
                  ...member,
                  defaultAvatarPath: getRandomAvatarPath(),
                  ...newCounters,
                };
              }
              return null;
            }),
          );

          // Filter out any null entries
          allUserDetails.push(...membersWithDetails.filter(Boolean));
        }),
      );

      // Sort allUserDetails by the 'coins' property in descending order before setting
      const sortedUserDetails = allUserDetails.sort(
        (a, b) => (b.coins || 0) - (a.coins || 0),
      );

      setUserDetails((prevDetails) => [...prevDetails, ...sortedUserDetails]);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch clans");
    }
  };

  const getRandomAvatarPath = () =>
    `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar${
      Math.floor(Math.random() * 8) + 1
    }.png`;

  // Handle "Load More" button click
  const handleLoadMore = () => fetchData(champType, timeline, pageToken);

  useEffect(() => {
    fetchData(champType, timeline);
  }, [champType, timeline]);

  if (loading && !userDetails.length) return <LinearProgress />;

  if (error)
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );

  const topChamps = userDetails.slice(0, 3);
  const leaderboard = userDetails.slice(3);

  const handleTimelineChange = (newTimeline: string) => {
    if (newTimeline !== timeline) {
      resetData();
      setTimeline(newTimeline);
    }
  };

  const handleChampTypeChange = (newChampType: string) => {
    if (newChampType !== champType) {
      resetData();
      setChampType(newChampType);
    }
  };

  const resetData = () => {
    setUserDetails([]);
    setPageToken(null);
    setStopFetching(false);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      sx={{
        mt: 5,
        color: "#1F1D3A",
        background: `url(${
          isMobile ? user_profile_mobile : user_profile_desktop
        })`,
      }}
    >
      <TimelineButtons
        timeline={timeline}
        handleTimelineChange={handleTimelineChange}
      />
      <TopChamps topChamps={topChamps} isMobile={isMobile} />
      {isMobile ? (
        <ChampIcons
          champType={champType}
          handleChampTypeChange={handleChampTypeChange}
        />
      ) : (
        <IconsContainer>
          <ChampIcons
            champType={champType}
            handleChampTypeChange={handleChampTypeChange}
          />
        </IconsContainer>
      )}
      <Leaderboard leaderboard={leaderboard} isMobile={isMobile} />
      {loading && pageToken && <LinearProgress />}
      {!loading && pageToken && (
        <Button
          onClick={handleLoadMore}
          variant="contained"
          size="large"
          sx={{
            mt: 2,
            backgroundColor: "#6525BA",
            borderRadius: "25px",
          }}
        >
          Load More
        </Button>
      )}
    </Grid>
  );
};

export default Champs;
