import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters } from "../types";
import masterpiece from "../assets/masterpiece.svg";
import treasure from "../assets/treasure.svg";
import hall_of_fame from "../assets/hall_of_fame.svg";

interface UserBadgesProps {
  userCounters: UserCounters | null;
  counterError: string | null;
  isMobile: boolean | null;
  gradientTextStyles: Function;
}

const UserBadges: React.FC<UserBadgesProps> = ({
  userCounters,
  counterError,
  isMobile,
  gradientTextStyles,
}) => {
  return (
    <Box
      mt={4}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={gradientTextStyles(isMobile)}
      >
        BADGES
      </Typography>
      <Grid container spacing={{ xs: 2, md: 10 }} justifyContent="center">
        {counterError ? (
          <Typography color="error">{counterError}</Typography>
        ) : (
          <>
            {[
              {
                label: "Masterpiece",
                value: userCounters?.diamond,
                icon: masterpiece,
              },
              {
                label: "Treasure",
                value: userCounters?.wonders,
                icon: treasure,
              },
              {
                label: "Hall of Fame",
                value: userCounters?.hall_of_fame,
                icon: hall_of_fame,
              },
            ].map(({ label, value, icon }, idx) => (
              <Grid
                key={idx}
                size={{ xs: 4, md: 4 }}
                sx={{ textAlign: "center" }}
              >
                <img
                  src={icon}
                  alt={label}
                  width={isMobile ? "75%" : "auto"}
                  height={isMobile ? "75%" : "auto"}
                />
                <Typography fontSize={{ xs: "13px", md: "22.46px" }}>
                  {label}
                </Typography>
                <Typography fontSize={{ xs: "13px", md: "22.46px" }}>
                  {value}
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default UserBadges;
