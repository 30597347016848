import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Contests = () => {
  return (
    <Box pt={10}>
      <Typography variant="h3">Contests Page</Typography>
      <p>Welcome to the contests Page</p>
    </Box>
  );
};

export default Contests;
