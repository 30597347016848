export const LATEST_API = "/api/v1/web/posts/badge/fe";
export const POPULAR_API = "/api/v1/web/posts/popular";
export const POST_DETAIL_API = "/api/v1/counts";
export const USERS_API = "/api/v1/web/users";
export const BOOKS_FEED_API = "/api/v1/web/books/feed";
export const LANDSCAPE_API = "/api/v1/web/posts/orientation/l";
export const PORTRAIT_API = "/api/v1/web/posts/orientation/p";
export const TRENDING_HOT_API = "/api/v1/web/posts/trending";
export const USER_BY_IDS_API = "/api/v1/web/users/getbyIds?ids=";
export const USER_COUNTER_API = "/api/v1/user/counter";
export const POST_BY_IDS_API = "/api/v1/web/posts/ids?ids=";
export const BOOKS_BY_IDS_API = "/api/v1/web/books";
export const USER_POSTS_API = "/api/v1/web/user/actions/post";
export const REACTIONS_API = "/api/v1/web/reactions";
export const COINS_API = "/api/v1/user/score-and-coins";
export const CLANS_LIST_API = "/api/v1/web/clans";
export const CLANS_DETAILS_API = "/api/v1/web/clan/details";
export const CHAMPS_API = "/api/v1/web/user/champs";
