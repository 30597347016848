import React from "react";
import { Grid2 as Grid } from "@mui/material";
import GradientButton from "../../svg/gradient-button";
import ActiveGradientButton from "../../svg/active-gradient-button";

// Define the types for the props
interface TimelineButtonsProps {
  timeline: string;
  handleTimelineChange: (newTimeline: string) => void;
}

const TimelineButtons: React.FC<TimelineButtonsProps> = ({
  timeline,
  handleTimelineChange,
}) => (
  <Grid container spacing={3}>
    <Grid>
      {timeline === "all_time" ? (
        <ActiveGradientButton
          text={"ALL TIME"}
          onClick={() => handleTimelineChange("all_time")}
        />
      ) : (
        <GradientButton
          text={"ALL TIME"}
          onClick={() => handleTimelineChange("all_time")}
        />
      )}
    </Grid>
    <Grid>
      {timeline === "current_month" ? (
        <ActiveGradientButton
          text={"MONTHLY"}
          onClick={() => handleTimelineChange("current_month")}
        />
      ) : (
        <GradientButton
          text={"MONTHLY"}
          onClick={() => handleTimelineChange("current_month")}
        />
      )}
    </Grid>
    <Grid>
      {timeline === "current_week" ? (
        <ActiveGradientButton
          text={"WEEKLY"}
          onClick={() => handleTimelineChange("current_week")}
        />
      ) : (
        <GradientButton
          text={"WEEKLY"}
          onClick={() => handleTimelineChange("current_week")}
        />
      )}
    </Grid>
  </Grid>
);

export default TimelineButtons;
