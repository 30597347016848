import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import star from "../assets/star.gif";
import heart from "../assets/heart.gif";
import great from "../assets/great.gif";
import lovely from "../assets/lovely.gif";
import omg from "../assets/omg.gif";
import epic from "../assets/epic.gif";
import share from "../assets/share.svg";
import { CounterData, UserDetail } from "../types";

interface PostDetailsCountersProps {
  counterData: CounterData | null;
  userData: UserDetail | null;
  isMobile: boolean | null;
}

const PostDetailsCounters: React.FC<PostDetailsCountersProps> = ({
  counterData,
  userData,
  isMobile,
}) => {
  const counterIcons = [
    { img: great, label: "GREAT", value: counterData?.cps?.N },
    { img: lovely, label: "LOVELY", value: counterData?.likes?.N },
    { img: omg, label: "OMG", value: counterData?.views?.N },
    { img: epic, label: "EPIC", value: counterData?.cws?.N },
  ];

  const renderDesktopCounters = () => (
    <Grid
      container
      columns={12}
      mt={2}
      mb={2}
      justifyContent="center"
      alignItems="center"
    >
      {/* Star Counter */}
      <Grid
        size={{ xs: 2, md: 2 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={star || ""}
          alt="star"
          style={{
            width: isMobile ? 40 : 60,
            height: isMobile ? 40 : 60,
            borderRadius: "10px",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.cws?.N ? parseInt(counterData.cws.N, 10) : 0}
        </Typography>
      </Grid>

      {/* Emoji-based PostDetailsCounters */}
      <Grid
        size={{ xs: 8, md: 8 }}
        style={{
          background: `linear-gradient(to right, #3c2967, #6d39a1, #9a42b6)`,
          borderRadius: "25px",
          color: "white",
          clipPath:
            "polygon(0% 3%, 15% 0%, 85% 0%, 100% 6%, 100% 91%, 95% 100%, 14% 100%, 0% 97%)",
        }}
      >
        <Grid
          container
          columns={4}
          justifyContent="space-around"
          alignItems="center"
          p="10px"
        >
          {counterIcons.map(({ img, label, value }) => (
            <Grid
              key={label}
              size="auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={img || ""}
                alt={label}
                style={{
                  width: isMobile ? 30 : 35,
                  height: isMobile ? 30 : 35,
                  borderRadius: "10px",
                  marginBottom: isMobile ? 4 : 0,
                }}
              />
              <span>
                <Typography variant={isMobile ? "inherit" : "body1"}>
                  {label}
                </Typography>
                <Typography
                  variant={isMobile ? "inherit" : "body1"}
                  align="center"
                >
                  ({value ? parseInt(value, 10) : 0})
                </Typography>
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Likes Counter */}
      <Grid
        size={{ xs: 2, md: 2 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={heart || ""}
          alt="heart"
          style={{
            width: isMobile ? 40 : 43,
            height: isMobile ? 40 : 43,
            borderRadius: "10px",
            marginRight: isMobile ? 8 : 16,
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.likes?.N ? parseInt(counterData.likes.N, 10) : 0}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderMobileCounters = () => (
    <Grid
      container
      columns={12}
      p={3}
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {/* Star Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={star || ""}
          alt="star"
          style={{
            width: 40,
            height: 50,
            borderRadius: "10px",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.cws?.N ? parseInt(counterData.cws.N, 10) : 0}
        </Typography>
      </Grid>
      {/* Likes Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={heart || ""}
          alt="heart"
          style={{
            width: 40,
            height: 40,
            borderRadius: "10px",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.likes?.N ? parseInt(counterData.likes.N, 10) : 0}
        </Typography>
      </Grid>
      {/* Likes Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={share || ""}
          alt="share"
          style={{
            width: 40,
            height: 40,
            borderRadius: "10px",
          }}
        />
        <Typography fontSize={12}>Share</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobile ? "h6" : "h4"}
            sx={{
              background:
                "linear-gradient(89.82deg, rgba(224, 39, 128, 0.9) 0.16%, rgba(250, 78, 118, 0.9) 0.17%, rgba(167, 132, 234, 0.9) 38.04%, rgba(236, 167, 92, 0.9) 62.96%, rgba(247, 141, 109, 0.9) 83.4%, rgba(207, 102, 198, 0.9) 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 400,
              letterSpacing: isMobile ? 1 : 3,
            }}
            fontSize={16}
            fontFamily="Somatic"
          >
            “Magic Bottle”
          </Typography>
        </Box>
      </Grid>
      <Grid size={12}>
        <Typography
          fontFamily="Somatic"
          fontSize={12}
          color="rgba(31, 29, 58, 0.5)"
          fontWeight={400}
        >
          {userData?.bio}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Grid
          container
          sx={{
            height: 79.2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            borderRadius: "20px",
            background:
              "linear-gradient(89.82deg, rgba(224, 39, 128, 0.2) 0.16%, rgba(250, 78, 118, 0.2) 0.17%, rgba(167, 132, 234, 0.2) 38.04%, rgba(236, 167, 92, 0.2) 62.96%, rgba(247, 141, 109, 0.2) 83.4%, rgba(207, 102, 198, 0.2) 99.85%)",
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
            textAlign: "center",
            fontSize: "10px"
          }}
        >
          {counterIcons.map(({ img, label, value }) => (
            <Grid
              key={label}
              size="auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={isMobile ? "inherit" : "body1"}>
                {label}
              </Typography>
              <img
                src={img || ""}
                alt={label}
                style={{
                  width: isMobile ? 30 : 35,
                  height: isMobile ? 30 : 35,
                  borderRadius: "10px",
                  marginBottom: isMobile ? 4 : 0,
                }}
              />
              <Typography
                variant={isMobile ? "inherit" : "body1"}
                align="center"
              >
                {value ? parseInt(value, 10) : 0}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return isMobile ? renderMobileCounters() : renderDesktopCounters();
};

export default PostDetailsCounters;
