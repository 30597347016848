import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  IconButton,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";
import axios from "axios";
import { Book, BookDetail } from "../../types";
import { BOOKS_BY_IDS_API, BOOKS_FEED_API } from "../../api/endpoint";

const avatars = [
  "/APP/BookAvatars/Avatar1.jpg",
  "/APP/BookAvatars/Avatar2.jpg",
  "/APP/BookAvatars/Avatar3.jpg",
  "/APP/BookAvatars/Avatar4.jpg",
  "/APP/BookAvatars/Avatar5.jpg",
];

const TopBooks = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [bookDetails, setBookDetails] = useState<BookDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const isFetching = useRef(false);
  const [activeSlide, setActiveSlide] = useState(2);
  const [stopExecution, setStopExecution] = useState<boolean | null>(false);

  // Media query for responsiveness
  const isMobile = useMediaQuery("(max-width:600px)");

  const slideWidth = isMobile ? 170 : 252;
  const slideHeight = isMobile ? 240 : 302;

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const fetchBooks = async (pageToken: string | null = null, count = 5) => {
    if (!stopExecution && !isFetching.current) {
      isFetching.current = true;

      try {
        const response = await axiosInstance.get(BOOKS_FEED_API, {
          params: { page: pageToken || "", count },
        });

        if (!response?.data?.data?.length) {
          setStopExecution(true);
          return;
        }

        setBooks((prevBooks) => [...prevBooks, ...response.data.data]);
        setCurrentPage(response.data.page);

        const bookIds = response.data.data.map((book: Book) => ({
          book_id: book.book_id,
          user_id: book.user_id,
        }));

        if (bookIds.length > 0) {
          const bookDetailsResponse = await axiosInstance.post(
            BOOKS_BY_IDS_API,
            { targetIds: bookIds },
          );
          setBookDetails((prevBookDetails) => [
            ...prevBookDetails,
            ...bookDetailsResponse.data.data,
          ]);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    }
  };

  useEffect(() => {
    setBooks([]);
    setBookDetails([]);
    setLoading(true);
    setError(null);
    setCurrentPage(null);
    fetchBooks();
  }, []);

  useEffect(() => {
    if (activeSlide === bookDetails.length - 1) {
      fetchBooks(currentPage);
    }
  }, [activeSlide, currentPage]);

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  const sliderData =
    bookDetails.length > 0
      ? bookDetails.map((bookDetail, index) => ({
          id: bookDetail?.book_id + index,
          bgColor: "white",
          icon: (
            <img
              src={`${process.env.REACT_APP_DEFAULT_AVATAR}/${bookDetail?.user_id}/BOOKS/IMAGES/medium/${bookDetail?.cover?.[0]?.name}`}
              alt={bookDetail?.title}
              width={slideWidth}
              height={slideHeight}
              className="slide-img"
            />
          ),
          title: bookDetail?.title || "Anonymous",
        }))
      : avatars.map((avatar, index) => ({
          id: `avatar-${index}`,
          bgColor: "white",
          icon: (
            <img
              src={process.env.REACT_APP_DEFAULT_AVATAR + avatar}
              alt={`Avatar ${index + 1}`}
              width={slideWidth}
              height={slideHeight}
              className="slide-img"
            />
          ),
          title: "",
        }));

  const next = () => {
    if (
      activeSlide < bookDetails.length - 1 ||
      activeSlide < sliderData.length - 1
    ) {
      setActiveSlide(activeSlide + 1);
    }
  };

  const prev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const getStyles = (index: number) => {
    const screenWidth = window.innerWidth;
    const baseTranslateX = isMobile ? screenWidth * 0.17 : screenWidth * 0.17;
    const baseTranslateZ = isMobile ? screenWidth * 0.3 : screenWidth * 0.25;

    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(-35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 8,
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 8,
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 7,
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 7,
      };
    }
  };

  const goToSlide = (index: number) => {
    setActiveSlide(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          background:
            "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: 400,
          letterSpacing: isMobile ? 1 : 3,
          mb: isMobile ? 5 : 5,
        }}
      >
        Top Books
      </Typography>
      {loading ? (
        <LinearProgress sx={{ width: "100%" }} color="secondary" />
      ) : (
        <Box sx={{ width: "100%", maxWidth: "80%" }}>
          {/* Slider Component */}
          <div
            className="slideC"
            {...handlers}
            style={{ width: slideWidth, height: slideHeight }}
          >
            {sliderData.map((item, i) => (
              <div
                key={item.id}
                className="slide"
                style={{
                  width: slideWidth,
                  height: slideHeight,
                  background: item.bgColor,
                  boxShadow: `0 5px 20px ${item.bgColor}30`,
                  ...getStyles(i),
                }}
                onClick={() => goToSlide(i)}
              >
                <div className="sliderContent">
                  {item.icon}
                  {item.title && (
                    <Typography color="black" align="center">
                      {item.title}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Navigation Buttons */}
          <div className="btns">
            <IconButton
              className="slider-btn prev-btn"
              onClick={prev}
              sx={{
                color: "red",
              }}
              aria-label="Previous slide"
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              className="slider-btn next-btn"
              onClick={next}
              sx={{
                color: "red",
              }}
              aria-label="Next slide"
            >
              <ArrowForward />
            </IconButton>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default TopBooks;
