import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import home_advantages from "../../assets/home_advantages.svg";
import SectionBackground from "../../components/section-background";

interface AdvantagesProps {
  isMobile?: Boolean | null;
}

const Advantages: React.FC<AdvantagesProps> = ({ isMobile }) => {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 10 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 5 },
          pl: { xs: 5, md: 5 },
        }}
      >
        <Grid size={{ xs: 12, md: 7 }}>
          <Box
            p={{ xs: 2, md: 6 }}
            sx={{ textAlign: isMobile ? "center" : "left" }}
          >
            <Typography
              color="#FFE834"
              variant={isMobile ? "h6" : "h3"}
              fontSize={{ xs: 19, md: 40 }}
              sx={
                isMobile
                  ? {
                      background:
                        "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontWeight: 700,
                      letterSpacing: 1,
                      mb: 2,
                    }
                  : {}
              }
            >
              Advantages Of ChampsVerse
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
              pt={{ xs: 1, md: 5 }}
            >
              <span>&#x2022;</span> Foster Positive Growth
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Inspire Excellence
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Promote Teamwork
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Engage Continuously
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Standardized Reward System
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Balance Digital Activities
            </Typography>
            <Typography
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span>&#x2022;</span> Empower Parents and Teachers
            </Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={home_advantages}
              alt="learning"
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Advantages;
