import React from "react";
import { Grid2 as Grid, Box, Avatar, Typography } from "@mui/material";
import pink_crown from "../../assets/pink_crown.gif";
import green_crown from "../../assets/green_crown.gif";
import blue_crown from "../../assets/blue_crown.gif";
import { useNavigate } from "react-router-dom";

// Define the types for a single champion
interface Champion {
  uid: string;
  defaultAvatarPath?: string;
  avatar: string;
  firstname?: string;
  coins: number;
  defaultAvatar: boolean;
}

// Define the props type for the component
interface TopChampsProps {
  topChamps: Champion[];
  isMobile: boolean;
}

const transparentStyle = {
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  letterSpacing: 1,
};

const renderChampion = (
  champ: Champion,
  index: number,
  isMobile: boolean,
  isFirst: boolean,
  crown: any,
  border: string,
  boxShadow: string,
) => {
  const navigate = useNavigate();

  return (
    <Grid
      key={champ.uid + index}
      size={{ xs: 4, sm: 4 }}
      sx={{ textAlign: "center" }}
      mt={!isFirst ? 5 : 0}
    >
      <Box
        sx={{ padding: 2, position: "relative", cursor: "pointer" }}
        onClick={() => navigate(`/user/${champ.uid}`)}
      >
        <div
          style={{
            position: "absolute",
            top: isMobile ? "-3%" : "-15%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          <img src={crown} alt="crown" style={{ width: 150, height: 150 }} />
        </div>
        <Avatar
          src={
            champ.defaultAvatar
              ? champ.defaultAvatarPath
              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${champ?.uid}/PROFILE/IMAGES/medium/${champ?.avatar}`
          }
          alt={champ.firstname}
          sx={{
            width: isMobile ? 90 : 193,
            height: isMobile ? 90 : 193,
            margin: "auto",
            mt: 3,
            border,
            boxShadow,
          }}
        />
        <Avatar
          sx={{
            width: 52,
            height: 52,
            textAlign: "center",
            color: "#FFFFFF",
            backdropFilter: "blur(16px)",
            background:
              "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
            borderRadius: "50%",
            p: 1,
            position: "absolute",
            left: "40%",
            bottom: 0,
          }}
        >
          {index + 1}
        </Avatar>
      </Box>
      <Typography
        variant="h6"
        sx={transparentStyle}
        mt={3}
        fontSize={24}
        fontFamily="Somatic"
      >
        {champ.firstname || "Anonymous"}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: "#1F1D3A" }}
        fontSize={20}
        fontFamily="Somatic"
      >
        {champ.coins} Points
      </Typography>
    </Grid>
  );
};

const TopChamps: React.FC<TopChampsProps> = ({ topChamps, isMobile }) => (
  <Grid container justifyContent="center" spacing={2} sx={{ mb: 4, mt: 5 }}>
    {topChamps[2] &&
      renderChampion(
        topChamps[2],
        2,
        isMobile,
        false,
        green_crown,
        "3.19px solid rgba(255, 168, 0, 1)",
        "0px 0px 11.55px 0px rgba(255, 218, 88, 1)",
      )}
    {topChamps[0] &&
      renderChampion(
        topChamps[0],
        0,
        isMobile,
        true,
        pink_crown,
        "3.19px solid rgba(255, 245, 0, 1)",
        "0px 0px 11.55px 0px rgba(250, 255, 0, 1)",
      )}
    {topChamps[1] &&
      renderChampion(
        topChamps[1],
        1,
        isMobile,
        false,
        blue_crown,
        "3.19px solid rgba(193, 193, 193, 1)",
        "0px 0px 11.55px 0px rgba(255, 255, 255, 1)",
      )}
  </Grid>
);

export default TopChamps;
