import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Grid2 as Grid,
  Typography,
  ButtonBase,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";
import axiosInstance from "../api/axiosConfig";
import PostDetailDialog from "./post-detail-dialog";
import latest_image from "../assets/latest_image.svg";
import explore_more from "../assets/explore_more.svg";
import { Post, PostDetail } from "../types";
import { LATEST_API, POST_BY_IDS_API, USER_POSTS_API } from "../api/endpoint";

interface LatestProps {
  isUserProfile?: boolean | null;
  userId?: string | null;
}

const Latest: React.FC<LatestProps> = ({ isUserProfile, userId }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [postDetails, setPostDetails] = useState<PostDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const isFetching = useRef(false);
  const [stopExecution, setStopExecution] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<PostDetail | null>(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const landscapeWidth = isMobile ? "42vw" : "18vw";
  const landscapeHeight = isMobile ? "30vh" : "53vh";
  const portraitHeight = isMobile ? "14vh" : "25vh";

  const fetchPosts = async (pageToken: string | null = null, count = 30) => {
    if (!stopExecution && !isFetching.current) {
      isFetching.current = true;

      try {
        const response = await axiosInstance.get(
          isUserProfile ? USER_POSTS_API : LATEST_API,
          {
            params: { page: pageToken || "", count, userId },
          }
        );

        if (!response?.data?.data?.length) {
          setStopExecution(true);
          return;
        }

        setPosts((prevPosts) => [...prevPosts, ...response.data.data]);
        setCurrentPage(response.data.page);

        const postIds = response.data.data.map((post: Post) => post.post_id);
        if (postIds.length > 0) {
          const postDetailsResponse = await axiosInstance.get(
            `${POST_BY_IDS_API}${postIds.join(",")}`
          );
          setPostDetails((prevPostDetails) => [
            ...prevPostDetails,
            ...postDetailsResponse.data.data,
          ]);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (postDetails.length < 30 && currentPage) {
      fetchPosts(currentPage, 30 - postDetails.length);
    }
  }, [postDetails, currentPage]);

  const validPostDetails = posts
    .map((post) =>
      postDetails.find((detail) => detail.post_id === post.post_id)
    )
    .filter((detail): detail is PostDetail => detail !== undefined);

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  const handlePostClick = (detail: PostDetail) => {
    setSelectedPost(detail);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedPost(null);
  };

  function getItemsInChunks(arr: any, chunkSize: number) {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: isMobile ? 5 : 10,
          mb: isMobile ? 5 : 10,
        }}
      >
        <img
          src={latest_image}
          alt="Latest Image"
          style={{ width: isMobile ? "80%" : "35%" }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, pl: isMobile ? 1 : 1, pr: isMobile ? 1 : 1 }}>
        {loading ? (
          <LinearProgress sx={{ width: "100%" }} color="secondary" />
        ) : (
          <Grid container spacing={2} justifyContent="space-evenly">
            {isMobile
              ? getItemsInChunks(validPostDetails, 6).map((details, index) => {
                  // Validate the existence of detail1, detail2, and detail3
                  const detail1 = details[0] || null;
                  const detail2 = details[1] || null;
                  const detail3 = details[2] || null;
                  const detail4 = details[3] || null;
                  const detail5 = details[4] || null;
                  const detail6 = details[5] || null;

                  // Prepare image URLs with fallback if details are missing
                  const imageUrl1 =
                    detail1?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail1?.user_id}/WORKS/IMAGES/medium/${detail1?.files[0]?.name}`;
                  const imageUrl2 =
                    detail2?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail2?.user_id}/WORKS/IMAGES/medium/${detail2?.files[0]?.name}`;
                  const imageUrl3 =
                    detail3?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail3?.user_id}/WORKS/IMAGES/medium/${detail3?.files[0]?.name}`;
                  const imageUrl4 =
                    detail4?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail4?.user_id}/WORKS/IMAGES/medium/${detail4?.files[0]?.name}`;
                  const imageUrl5 =
                    detail5?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail5?.user_id}/WORKS/IMAGES/medium/${detail5?.files[0]?.name}`;
                  const imageUrl6 =
                    detail6?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail6?.user_id}/WORKS/IMAGES/medium/${detail6?.files[0]?.name}`;

                  return (
                    <React.Fragment key={index}>
                      {detail1 && (
                        <Grid
                          size={{ xs: 6, md: 2.25 }}
                          sx={{ textAlign: "center" }}
                          onClick={() => handlePostClick(detail1)}
                        >
                          <img
                            src={imageUrl1}
                            alt={detail1.text || "Post Image"}
                            style={{
                              width: landscapeWidth,
                              height: landscapeHeight,
                              border: isMobile ? "none" : "10px solid #FFFFFF",
                              borderRadius: isMobile ? "16px" : "25px",
                              boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src =
                                process.env.REACT_APP_NO_IMAGE || "";
                            }}
                          />
                        </Grid>
                      )}
                      {detail2 && (
                        <Grid size={{ xs: 6, md: 2.25 }}>
                          <Grid container size={12} direction="column">
                            <Grid
                              sx={{ textAlign: "center" }}
                              onClick={() => handlePostClick(detail2)}
                            >
                              <img
                                src={imageUrl2}
                                alt={detail2.text || "Post Image"}
                                style={{
                                  width: landscapeWidth,
                                  height: portraitHeight,
                                  border: isMobile
                                    ? "none"
                                    : "10px solid #FFFFFF",
                                  borderRadius: isMobile ? "16px" : "25px",
                                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src =
                                    process.env.REACT_APP_NO_IMAGE || "";
                                }}
                              />
                            </Grid>
                            {detail3 && (
                              <Grid
                                sx={{ textAlign: "center" }}
                                onClick={() => handlePostClick(detail3)}
                              >
                                <img
                                  src={imageUrl3}
                                  alt={detail3.text || "Post Image"}
                                  style={{
                                    width: landscapeWidth,
                                    height: portraitHeight,
                                    border: isMobile
                                      ? "none"
                                      : "10px solid #FFFFFF",
                                    borderRadius: isMobile ? "16px" : "25px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  }}
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src =
                                      process.env.REACT_APP_NO_IMAGE || "";
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {detail4 && (
                        <Grid size={{ xs: 6, md: 2.25 }}>
                          <Grid container size={12} direction="column">
                            <Grid
                              sx={{ textAlign: "center" }}
                              onClick={() => handlePostClick(detail4)}
                            >
                              <img
                                src={imageUrl4}
                                alt={detail4.text || "Post Image"}
                                style={{
                                  width: landscapeWidth,
                                  height: portraitHeight,
                                  border: isMobile
                                    ? "none"
                                    : "10px solid #FFFFFF",
                                  borderRadius: isMobile ? "16px" : "25px",
                                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src =
                                    process.env.REACT_APP_NO_IMAGE || "";
                                }}
                              />
                            </Grid>
                            {detail5 && (
                              <Grid
                                sx={{ textAlign: "center" }}
                                onClick={() => handlePostClick(detail5)}
                              >
                                <img
                                  src={imageUrl5}
                                  alt={detail5.text || "Post Image"}
                                  style={{
                                    width: landscapeWidth,
                                    height: portraitHeight,
                                    border: isMobile
                                      ? "none"
                                      : "10px solid #FFFFFF",
                                    borderRadius: isMobile ? "16px" : "25px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  }}
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src =
                                      process.env.REACT_APP_NO_IMAGE || "";
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {detail6 && (
                        <Grid
                          size={{ xs: 6, md: 2.25 }}
                          sx={{ textAlign: "center" }}
                          onClick={() => handlePostClick(detail6)}
                        >
                          <img
                            src={imageUrl6}
                            alt={detail6.text || "Post Image"}
                            style={{
                              width: landscapeWidth,
                              height: landscapeHeight,
                              border: isMobile ? "none" : "10px solid #FFFFFF",
                              borderRadius: isMobile ? "16px" : "25px",
                              boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src =
                                process.env.REACT_APP_NO_IMAGE || "";
                            }}
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })
              : getItemsInChunks(validPostDetails, 3).map((details, index) => {
                  // Validate the existence of detail1, detail2, and detail3
                  const detail1 = details[0] || null;
                  const detail2 = details[1] || null;
                  const detail3 = details[2] || null;

                  // Prepare image URLs with fallback if details are missing
                  const imageUrl1 =
                    detail1?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail1?.user_id}/WORKS/IMAGES/medium/${detail1?.files[0]?.name}`;
                  const imageUrl2 =
                    detail2?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail2?.user_id}/WORKS/IMAGES/medium/${detail2?.files[0]?.name}`;
                  const imageUrl3 =
                    detail3?.imageUrl ||
                    `${process.env.REACT_APP_DEFAULT_AVATAR}/${detail3?.user_id}/WORKS/IMAGES/medium/${detail3?.files[0]?.name}`;

                  return (
                    <React.Fragment key={index}>
                      {detail1 && (
                        <Grid
                          size={{ xs: 6, md: 2.25 }}
                          sx={{ textAlign: "center" }}
                          onClick={() => handlePostClick(detail1)}
                        >
                          <img
                            src={imageUrl1}
                            alt={detail1.text || "Post Image"}
                            style={{
                              width: landscapeWidth,
                              height: landscapeHeight,
                              border: isMobile ? "none" : "10px solid #FFFFFF",
                              borderRadius: isMobile ? "16px" : "25px",
                              boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src =
                                process.env.REACT_APP_NO_IMAGE || "";
                            }}
                          />
                        </Grid>
                      )}
                      {detail2 && (
                        <Grid size={{ xs: 6, md: 2.25 }}>
                          <Grid container size={12} direction="column">
                            <Grid
                              sx={{ textAlign: "center" }}
                              onClick={() => handlePostClick(detail2)}
                            >
                              <img
                                src={imageUrl2}
                                alt={detail2.text || "Post Image"}
                                style={{
                                  width: landscapeWidth,
                                  height: portraitHeight,
                                  border: isMobile
                                    ? "none"
                                    : "10px solid #FFFFFF",
                                  borderRadius: isMobile ? "16px" : "25px",
                                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src =
                                    process.env.REACT_APP_NO_IMAGE || "";
                                }}
                              />
                            </Grid>
                            {detail3 && (
                              <Grid
                                sx={{ textAlign: "center" }}
                                onClick={() => handlePostClick(detail3)}
                              >
                                <img
                                  src={imageUrl3}
                                  alt={detail3.text || "Post Image"}
                                  style={{
                                    width: landscapeWidth,
                                    height: portraitHeight,
                                    border: isMobile
                                      ? "none"
                                      : "10px solid #FFFFFF",
                                    borderRadius: isMobile ? "16px" : "25px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.2) 0px 8px 24px",
                                  }}
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src =
                                      process.env.REACT_APP_NO_IMAGE || "";
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })}
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: isMobile ? 2 : 10,
        }}
      >
        <ButtonBase
          onClick={() => fetchPosts(currentPage)}
          disabled={stopExecution}
          sx={{
            width: isMobile ? "20vw" : "15vw",
            height: "8vh",
          }}
        >
          <img
            src={explore_more}
            alt="Explore More"
            style={{ width: isMobile ? "20vw" : "15vw" }}
          />
        </ButtonBase>
      </Box>
      <PostDetailDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        postDetail={selectedPost}
      />
    </>
  );
};

export default Latest;
