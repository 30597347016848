import React, { useState } from "react";
import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import home_learning from "../../assets/home_learning.svg";
import SectionBackground from "../../components/section-background";

interface LearningProps {
  isMobile?: Boolean | null;
}

const Learning: React.FC<LearningProps> = ({ isMobile }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderImage = () => (
    <Grid size={{ xs: 12, md: 4 }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          src={home_learning}
          alt="learning"
          style={{
            width: isMobile ? "100%" : 570,
            height: isMobile ? "100%" : 570,
          }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
      mt={isMobile ? 0 : -8}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 14 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 5 },
          pl: { xs: 5, md: 5 },
        }}
      >
        {!isMobile && renderImage()}
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box p={6}>
            <Typography color="#FFE834" variant="h3" fontSize={40}>
              Fun Learning, Safe Growing, Bright Futures
            </Typography>
            <Typography
              color={isMobile ? "#0000" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              pt={5}
              fontFamily="Somatic"
              textAlign="justify"
            >
              Welcome to Champsverse, where we’re transforming childhood into an
              exciting and safe adventure. Through our unique rewards system, we
              make learning fun, engaging, and rewarding. At the same time, we
              focus on your child’s mental and physical well-being, ensuring
              they have the tools to grow both inside and outside the classroom.
              We are committed to shielding kids from the negative aspects of
              the digital world, while giving them the positive guidance and
              motivation they need to thrive. With Champsverse, your kids can
              explore, learn, and flourish in a nurturing environment designed
              just for them.
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              background:
                "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 700,
              letterSpacing: 1,
              mb: 2,
            }}
            fontSize={20}
            align="center"
          >
            Fun Learning, Safe Growing, Bright Futures
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "block", md: "none" },
            textAlign: "justify",
          }}
        >
          <Typography
            align="center"
            fontWeight="normal"
            variant="inherit"
            fontFamily="Somatic"
            fontSize={14}
            color="#1F1D3AB2"
          >
            Welcome to Champsverse, where we’re transforming childhood into an
            exciting and safe adventure. Through our unique rewards system, we
            make learning fun, engaging, and rewarding. At the{" "}
            {showMore ? (
              <>
                same time, we focus on your child’s mental and physical
                well-being, ensuring they have the tools to grow both inside and
                outside the classroom. We are committed to shielding kids from
                the negative aspects of the digital world, while giving them the
                positive guidance and motivation they need to thrive. With
                Champsverse, your kids can explore, learn, and flourish in a
                nurturing environment designed just for them.
              </>
            ) : (
              "..."
            )}
            <Button
              onClick={toggleShowMore}
              sx={{
                padding: 0,
                minWidth: "unset",
                textTransform: "none",
                fontSize: "inherit",
                fontWeight: "bold",
                color: "#ECA75C",
              }}
            >
              {showMore ? "Read Less" : "Read More"}
            </Button>
          </Typography>
        </Grid>
        {isMobile && renderImage()}
      </Grid>
    </Box>
  );
};

export default Learning;
