import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import c_achievement from "../../assets/c_achievement.png";
import c_activity from "../../assets/c_activity.png";
import c_clans from "../../assets/c_clans.png";
import c_coin from "../../assets/c_coin.png";
import c_goodness from "../../assets/c_goodness.png";
import c_popularity from "../../assets/c_popularity.png";

// Define the types for the props
interface ChampIconsProps {
  champType: string;
  handleChampTypeChange: (type: string) => void;
}

const iconStyles = {
  active: {
    border: "2.02px solid rgba(253, 210, 101, 1)",
    boxShadow:
      "inset -4px -4px 20px rgba(255, 255, 255, 0.2), inset 4px 4px 20px rgba(0, 0, 0, 0.3), 0px 5px 15px rgba(0, 0, 0, 0.4)",
    cursor: "pointer",
  },
  default: {
    cursor: "pointer",
  },
};

const ChampIcons: React.FC<ChampIconsProps> = ({
  champType,
  handleChampTypeChange,
}) => {
  const renderIcon = (src: string, label: string, type: string) => (
    <Grid
      size={{ xs: 4, sm: "grow" }}
      pl={2}
      pr={2}
      sx={{ textAlign: "center" }}
    >
      <img
        src={src}
        alt={label}
        width={50}
        height={50}
        style={champType === type ? iconStyles.active : iconStyles.default}
        onClick={() => handleChampTypeChange(type)}
      />
      <Typography color="rgba(31, 29, 58, 0.6)" fontSize={9}>{label}</Typography>
    </Grid>
  );
  return (
    <Grid container justifyContent="center" alignItems="center">
      {renderIcon(c_coin, "COINS", "coins")}
      {renderIcon(c_activity, "ACTIVITY", "cws")}
      {renderIcon(c_popularity, "POPULARITY", "cps")}
      {renderIcon(c_goodness, "GOODNESS", "cgs")}
      {renderIcon(c_achievement, "ACHIEVEMENT", "cas")}
      {renderIcon(c_clans, "CLANS", "clans")}
    </Grid>
  );
};

export default ChampIcons;
