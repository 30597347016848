import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  Toolbar,
  AppBar,
  useMediaQuery,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.png";
import homeHero from "../assets/home_hero.svg";
import logo_black from "../assets/logo_black.png";
import uploadYourWork from "../assets/upload_your_work.svg";
import mobile_hero from "../assets/mobile_hero.png";
import content_background from "../assets/content_background.svg";
import uploadYourWorkMobile from "../assets/upload_your_work_mobile.png";

interface HeaderProps {
  scrollToAppDownload: () => void;
}

const Header: React.FC<HeaderProps> = ({ scrollToAppDownload }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const isHomePage = location.pathname === "/";

  return (
    <Box sx={{ position: "relative" }}>
      {isMobile && isHomePage && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: isMobile ? "50vh" : "100vh",
            zIndex: -1,
            overflow: "hidden", // Ensure it doesn't overflow outside the header
          }}
        >
          <img
            src={mobile_hero}
            alt="header banner"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      )}

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ position: "relative", zIndex: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <NavLink to="/" end>
              <img
                src={isHomePage ? logo : logo_black}
                alt="Logo"
                style={{
                  height: isMobile ? "50px" : "58.44px",
                  marginTop: "15px",
                }}
              />
            </NavLink>
          </Box>

          {/* Desktop Links */}
          <Box
            sx={{ flexGrow: 1, justifyContent: "center" }}
            className="desktop-menu"
          >
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ color: isHomePage ? "white" : "#1F1D3AB2" }}
            >
              <Typography>Home</Typography>
            </NavLink>
            <NavLink
              to="/champs"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ color: isHomePage ? "white" : "#1F1D3AB2" }}
            >
              <Typography>Champs</Typography>
            </NavLink>
            <NavLink
              to="/contests"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ color: isHomePage ? "white" : "#1F1D3AB2" }}
            >
              <Typography>Contests</Typography>
            </NavLink>
          </Box>
          <Box sx={{ flexGrow: 3 }} className="desktop-menu">
            <NavLink
              to="/register"
              className={({ isActive }) =>
                isActive ? "active-link" : "active-link"
              }
            >
              <Typography
                sx={{
                  padding: "7px 15px",
                }}
              >
                Register
              </Typography>
            </NavLink>
          </Box>

          {/* Hamburger Icon for Mobile */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "block", md: "none" }, mt: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Drawer for Mobile Menu */}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
            >
              <List>
                <NavLink to="/" style={{ color: "#1F1D3AB2" }}>
                  <ListItem>
                    <ListItemText primary="HOME" />
                  </ListItem>
                </NavLink>
                <NavLink to="/champs" style={{ color: "#1F1D3AB2" }}>
                  <ListItem>
                    <ListItemText primary="CHAMPS" />
                  </ListItem>
                </NavLink>
                <NavLink to="/contests" style={{ color: "#1F1D3AB2" }}>
                  <ListItem>
                    <ListItemText primary="CONTESTS" />
                  </ListItem>
                </NavLink>
                <NavLink to="/register" style={{ color: "#1F1D3AB2" }}>
                  <ListItem>
                    <ListItemText primary="REGISTER" />
                  </ListItem>
                </NavLink>
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>

      {/* Hero Section Content over the image */}
      {isHomePage && (
        <>
          {!isMobile && (
            <img
              src={homeHero}
              alt="header banner"
              style={{ width: "100%", marginTop: "-100px" }}
            />
          )}

          <Box
            sx={{
              position: isMobile ? "relative" : "absolute",
              top: isMobile ? "20%" : "30%",
              left: 0,
              width: "100%",
              // textAlign: "center",
            }}
          >
            <img
              src={isMobile ? uploadYourWorkMobile : uploadYourWork}
              onClick={scrollToAppDownload}
              alt="Upload your work"
              style={{ width: isMobile ? "45%" : "40%", cursor: "pointer" }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Header;
